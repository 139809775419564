import React from "react"
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  RedditShareButton,
  RedditIcon,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  PinterestIcon,
  EmailIcon,
} from "react-share"

const SocialShare = ({ url, quote, media }) => {
  return (
    <div className="flex gap-2">
      <FacebookShareButton
        url={`https://maltamarijuana.com${url}`}
        quote={quote}
      >
        <FacebookIcon size={32} round />
      </FacebookShareButton>

      <TwitterShareButton
        url={`https://maltamarijuana.com${url}`}
        title={quote}
      >
        <TwitterIcon size={32} round />
      </TwitterShareButton>

      <WhatsappShareButton
        url={`https://maltamarijuana.com${url}`}
        title={quote}
      >
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>

      <PinterestShareButton
        url={`https://maltamarijuana.com${url}`}
        description={quote}
        media={`https://maltamarijuana.com${media}`}
      >
        <PinterestIcon size={32} round />
      </PinterestShareButton>

      <LinkedinShareButton
        url={`https://maltamarijuana.com${url}`}
        title={quote}
      >
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>

      <RedditShareButton url={`https://maltamarijuana.com${url}`} title={quote}>
        <RedditIcon size={32} round />
      </RedditShareButton>

      <EmailShareButton
        url={`https://maltamarijuana.com${url}`}
        subject={quote}
      >
        <EmailIcon size={32} round />
      </EmailShareButton>
    </div>
  )
}

export default SocialShare
