import * as React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Seo from "../components/seo"
import SocialShare from "../components/SocialShare"
import { GatsbyImage } from "gatsby-plugin-image"

const BlogPostTemplate = ({ data }) => {
  const post = data.mdx
  const { previous, next } = data
  const { title, description, cover, date, category, updated } =
    post.frontmatter

  const isUpdated = date !== updated

  return (
    <>
      <Seo
        title={title}
        description={description || post.excerpt}
        image={cover.publicURL}
      />
      <article itemScope itemType="http://schema.org/Article">
        <GatsbyImage
          image={cover.childImageSharp.gatsbyImageData}
          className="cover-image"
          alt={title}
        />
        <header>
          <h1 itemProp="headline" className="mb-4 mt-6 text-3xl md:text-4xl">
            {title}
          </h1>

          {isUpdated ? (
            <p className="text-sm font-semibold mb-6">Updated: {updated}</p>
          ) : (
            <p className="text-sm font-semibold mb-6">{date}</p>
          )}
        </header>

        <section itemProp="articleBody" className="blog-post">
          <MDXRenderer>{post.body}</MDXRenderer>
        </section>
        <div className="pb-4 mt-10">
          <p className="font-semibold mb-2">Share the knowledge!</p>
          <SocialShare
            url={`${post.fields.slug}`}
            quote={title}
            media={cover.publicURL}
          />
        </div>
        <hr />
      </article>
      <nav className="mt-4">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={`${previous.fields.slug}`} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={`${next.fields.slug}`} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      body
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        updated(formatString: "MMMM DD, YYYY")
        description
        category
        cover {
          childImageSharp {
            gatsbyImageData(
              quality: 80
              placeholder: BLURRED
              layout: FULL_WIDTH
              sizes: "850"
              formats: [AUTO, WEBP, AVIF]
            )
          }
          publicURL
        }
      }
    }
    previous: mdx(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
        category
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
        category
      }
    }
  }
`
